// Generated by Framer (0f22d58)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["KQxtQse8L"];

const variantClassNames = {KQxtQse8L: "framer-v-4qsr9n"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "KQxtQse8L", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-GLmf5", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-4qsr9n", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"KQxtQse8L"} ref={ref} style={{...style}}><SVG className={"framer-1obiecw"} data-framer-name={"activity"} layout={"position"} layoutDependency={layoutDependency} layoutId={"w4uCTkys1"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 22 12 L 18 12 L 15 21 L 9 3 L 6 12 L 2 12\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-801172cb-bf10-456d-b6c0-e7ed31654a54, rgb(10, 10, 10)) /* {&quot;name&quot;:&quot;Text / Main&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={3030570000} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-GLmf5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GLmf5 .framer-fa6yin { display: block; }", ".framer-GLmf5 .framer-4qsr9n { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-GLmf5 .framer-1obiecw { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-GLmf5 .framer-4qsr9n { gap: 0px; } .framer-GLmf5 .framer-4qsr9n > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-GLmf5 .framer-4qsr9n > :first-child { margin-left: 0px; } .framer-GLmf5 .framer-4qsr9n > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 */
const FrameryNYAJcsoA: React.ComponentType<Props> = withCSS(Component, css, "framer-GLmf5") as typeof Component;
export default FrameryNYAJcsoA;

FrameryNYAJcsoA.displayName = "Icons";

FrameryNYAJcsoA.defaultProps = {height: 24, width: 24};

addFonts(FrameryNYAJcsoA, [])